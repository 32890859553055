const navbar = document.getElementById('navbar-home') || document.getElementById('navbar');
const menuTrigger = document.getElementById('menu-trigger');
const menuElement = document.getElementById("menu");

let menuVisible = false;

menuTrigger.addEventListener("click", toggleMenu);

function toggleMenu() {
    if (menuElement) {
        menuElement.classList.toggle("!opacity-0");
        menuElement.classList.toggle("pointer-events-none");
        menuVisible = !menuVisible;
        updateNavbarBackground();
        updateNavbarHeight();
        toggleBodyScroll();
    }
}

let currentVisibleItem = null;
const tabtriggers = document.querySelectorAll(".tab-triggers");

for (const element of tabtriggers) {
    element.addEventListener("keydown", function(e) {
        if (e.key === "Tab") {
            const item = this.querySelectorAll(".tab-content")[0];
            if (item) {
                if (currentVisibleItem && currentVisibleItem !== item) {
                    currentVisibleItem.classList.remove("grid");
                    currentVisibleItem.classList.add("hidden");
                }
                item.classList.remove("hidden");
                item.classList.add("grid");
                currentVisibleItem = item;
            }
        }
    });
}

const submenus = document.querySelectorAll(".submenu-trigger");
for (const element of submenus) {
    element.addEventListener("click", function() {
        const submenu = this.querySelectorAll(".submenu")[0];
        if (submenu) {
            submenu.classList.toggle("!max-h-0");

        }
        element.classList.toggle("[&>div:first-child]:bg-typography-white/10");
    });
}

function updateNavbarBackground() {
    if (document.getElementById('navbar-home')) {
        if (menuVisible || window.scrollY > 100) {
            navbar.classList.add("bg-secondary");
            navbar.classList.remove("bg-transparent");
        } else {
            navbar.classList.remove("bg-secondary");
            navbar.classList.add("bg-transparent");
        }
    }

}

function updateNavbarHeight() {
    if (navbar) {
        if (menuVisible) {
            // If menu is visible, set height to 100dvh so it covers the whole screen
            navbar.classList.add("h-dvh");
        } else {
            navbar.classList.remove("h-dvh");
        }
    }
}

function toggleBodyScroll() {
    if (menuVisible) {
        // Prevent scrolling of the background when menu is visible
        document.body.style.overflow = 'hidden';
    } else {
        document.body.style.overflow = '';
    }
}

// navbar-home only exists on homepage
if (document.getElementById('navbar-home')) {
    document.addEventListener('scroll', updateNavbarBackground);
    updateNavbarBackground(); // Initial check
}